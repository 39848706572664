import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../utils/format-price";

function SellerHistoryPriceModal({ lots }) {
  const { user } = useSelector((state) => state.profileGet);

  return (
    <div className="col-lg-6">
      <h2 className="seller-modal__repeat-price-title">История ставок</h2>
      {(() => {
        const maxPrice = Math.max(
          ...(lots?.greade_price.map((item) => item.price) || [])
        );
        return lots?.greade_price.map((item) => {
          return (
            <>
              {user?.data.role === "Seller" ? (
                <div className="d-flex justify-content-between align-items-center seller-modal__repeat-price">
                  <div className="seller-modal__repeat-price-time">
                    {moment(item?.updated_at).format("DD.MM.YYYY HH:mm")}
                  </div>
                  <div
                    className={`seller-modal__repeat-price-price ${
                      item.price === maxPrice ? "active" : ""
                    }`}
                  >
                    {formatNumber(item.price)} ₽
                  </div>
                </div>
              ) : (
                <Link
                  to={`/profile/${item.user_id}`}
                  target="_blank"
                  key={item.id}
                  className="d-flex justify-content-between align-items-center seller-modal__repeat-price"
                >
                  <div className="seller-modal__repeat-price-time">
                    {moment(item?.updated_at).format("DD.MM.YYYY HH:mm")}
                  </div>
                  <div
                    className={`seller-modal__repeat-price-price ${
                      item.price === maxPrice ? "active" : ""
                    }`}
                  >
                    {formatNumber(item.price)} ₽
                  </div>
                </Link>
              )}
            </>
          );
        });
      })()}
    </div>
  );
}

export default SellerHistoryPriceModal;
