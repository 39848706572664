import { addDays } from "date-fns";
import ru from "date-fns/locale/ru";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { companiesIndex } from "../../../store/insurance-companies/insuranceCompaniesIndexSlice/insuranceCompaniesIndexSlice";
import {
  auto_cat,
  auto_category,
  auto_diagnostics,
  auto_legal,
  auto_transaction,
} from "../../../utils/lots-create-array";
import { customNoOptionsMessage } from "../../../utils/option-not-found";
import selectStyle from "../../../utils/select-styles";
import SearchCity from "../../search-city/search-city";

function AutoParameters({
  setKey,
  setCreateData,
  createData,
  isTab1Disabled,
  setIsTab1Disabled,
  market,
}) {
  const dispatch = useDispatch();

  const onClickTab = () => {
    const insuranceValidate = ["Admin", "Manager"].includes(user?.data?.role)
      ? !createData.insurance_companies_id
      : "";

    // Валидация
    let isInputEmpty = false;
    if (market) {
      isInputEmpty =
        !createData.vin ||
        !createData.auto_cat ||
        !createData.attribute_diagnostics ||
        !createData.attribute_transaction ||
        !createData.region_id;
    } else {
      isInputEmpty =
        !createData.vin ||
        !createData.cat_auto ||
        !createData.end_auction ||
        !createData.binding_offer ||
        !createData.region_id ||
        insuranceValidate;
    }

    setIsTab1Disabled(isInputEmpty);
    if (!isInputEmpty) {
      setKey(market ? "auto-tab-2" : "seller-tab-2");
    }
  };
  registerLocale("ru", ru);
  const { user } = useSelector((state) => state.profileGet);

  const [selectedOption, setSelectedOption] = useState({});
  const [selectedDdiagnostics, setSelectedDdiagnostics] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedLegal, setSelectedLegal] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [companiesArray, setCompaniesArray] = useState([]);

  useEffect(() => {
    if (!createData || Object.keys(createData).length === 0) return; // Не запускаем эффект, если данных еще нет
    // Запись для select
    let catArray = market ? auto_category : auto_cat;
    const selectedOption = catArray.find(
      (option) =>
        option.value === (market ? createData.auto_cat : createData.cat_auto)
    );
    // Запись для diagnostics
    const selectedDdiagnostics = auto_diagnostics("attribute_diagnostics").find(
      (option) => option.value === createData.attribute_diagnostics
    );

    // Запись для diagnostics
    const selectedTransaction = auto_transaction.find(
      (option) => option.value === createData.attribute_transaction
    );

    // Запись для diagnostics
    let selectedLegal = {};
    if (createData.legal) {
      let legalValues;
      if (Array.isArray(createData.legal)) {
        legalValues = createData.legal;
      } else {
        legalValues = createData.legal.split(",");
      }
      selectedLegal = auto_legal.filter((item) =>
        legalValues.includes(item.value.trim())
      );
    }

    const companySelected = companiesArray.find(
      (option) => option.value === createData.insurance_companies_id
    );

    setSelectedOption(selectedOption);
    setSelectedDdiagnostics(selectedDdiagnostics);
    setSelectedTransaction(selectedTransaction);
    setSelectedLegal(selectedLegal);
    setSelectedCompany(companySelected);
  }, [
    createData.auto_cat,
    createData.cat_auto,
    createData.attribute_diagnostics,
    createData.attribute_transaction,
    createData.legal,
    createData.insurance_companies_id,
  ]);

  useEffect(() => {
    if (createData.end_auction) {
      const parsedDate = moment(createData.end_auction, "MM.DD.YYYY HH:mm:ss");
      setSelectedDate(parsedDate.toDate());
      setSelectedTime(parsedDate.toDate());
    }
  }, [createData.end_auction]);

  useEffect(() => {
    dispatch(companiesIndex()).then((res) => {
      if (res.payload.status === 200) {
        const updatedCompaniesArray = res.payload.data.insurances.data.map(
          (item) => ({
            value: item.id,
            label: item.title,
            name: "insurance_companies_id",
          })
        );
        setCompaniesArray((prevArray) => [...updatedCompaniesArray]);
      }
    });
  }, []);

  const handleInputChange = (event) => {
    let { name, value } = event.target ? event.target : event;
    let numericValue = value;

    // Remove commas and convert the input value to a number
    if (name === "restoration_price" || name === "recommended_price_gots") {
      numericValue = parseFloat(value.replace(/,/g, ""));
    }

    if (
      name === "price" ||
      name === "price_discount" ||
      name === "price_alls"
    ) {
      // Убираем запятые и проверяем значение
      numericValue = numericValue
        ? parseFloat(numericValue.replace(/,/g, ""))
        : 0;

      // Если результат parseFloat вернул NaN, ставим 0
      if (isNaN(numericValue)) {
        numericValue = 0;
      }
    }

    if (Array.isArray(event)) {
      console.log(event);
      name = "legal";
      numericValue = event
        .filter((item) => item && item.value !== undefined) // фильтруем undefined значения
        .map((item) => item.value)
        .join(",");
    }

    setCreateData((prevData) => ({ ...prevData, [name]: numericValue }));
  };

  const onChangeCity = (event) => {
    const { name, value } = event;
    console.log(event);

    setCreateData({ ...createData, [name]: value });
  };

  // Обновляем состояние при изменении даты
  const handleDateChange = (date) => {
    setSelectedDate(date);
    updateEndAuction(date, selectedTime);
  };

  // Обновляем состояние при изменении времени
  const handleTimeChange = (time) => {
    setSelectedTime(time);
    updateEndAuction(selectedDate, time);
  };

  // Функция для обновления состояния end_auction
  const updateEndAuction = (date, time) => {
    if (date && time) {
      const combinedDateTime = moment(date)
        .set({
          hour: moment(time).hours(),
          minute: moment(time).minutes(),
          second: moment(time).seconds(),
        })
        .format("MM.DD.YYYY HH:mm:ss");

      setCreateData((prevData) => ({
        ...prevData,
        end_auction: combinedDateTime,
      }));
    }
  };

  return (
    <div className="add-car-data">
      <h3>{market ? "Параметры авто" : "Параметры лота"}</h3>
      {/* Категория авто */}
      <div className="add-car-data__item">
        <h4>Категория авто</h4>
        <div className="add-car-data__flex input-tow-col">
          <div className={`input-wrap ${isTab1Disabled && "requerid-filed"}`}>
            <span>Категория авто</span>
            <Select
              className="input-style"
              defaultValue={selectedOption}
              noOptionsMessage={customNoOptionsMessage}
              value={selectedOption}
              options={market ? auto_category : auto_cat}
              styles={selectStyle}
              placeholder="Не указано"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      {/*  */}
      {/* >Административная информация */}
      <div className="add-car-data__item">
        <h4>Административная информация</h4>
        <div className="add-car-data__flex input-tow-col">
          <div className={`input-wrap ${isTab1Disabled && "requerid-filed"}`}>
            <span>VIN</span>
            <input
              type="text"
              className="input-style"
              placeholder="Не указано"
              name="vin"
              onChange={handleInputChange}
              defaultValue={createData.vin}
            />
          </div>
          <div className="input-wrap">
            <span>Гос.номер</span>
            <input
              type="text"
              className="input-style "
              placeholder="Не указано"
              name="license_plate"
              onChange={handleInputChange}
              defaultValue={createData.license_plate}
            />
          </div>
          <div className="input-wrap">
            <span>{market ? "Номер договора" : "№ дела/убытка"}</span>
            <input
              type="text"
              className="input-style "
              placeholder="Не указано"
              name={market ? "bitrix" : "insurance_contract_number"}
              onChange={handleInputChange}
              defaultValue={
                market
                  ? createData.bitrix
                  : createData.insurance_contract_number
              }
            />
          </div>
        </div>
      </div>
      {/*  */}

      {/* Информация о продавце */}

      <div className="add-car-data__item">
        <h4>Информация о продавце</h4>
        <div className="add-car-data__flex input-tow-col">
          <div className="input-wrap">
            <span>ФИО владельца</span>
            <input
              type="text"
              className="input-style "
              placeholder="Не указано"
              name="courier_fullname"
              onChange={handleInputChange}
              defaultValue={createData.courier_fullname}
            />
          </div>
          <div className="input-wrap">
            <span>Телефон владельца</span>
            <input
              type="text"
              className="input-style "
              placeholder="Не указано"
              name="courier_phone"
              onChange={handleInputChange}
              defaultValue={createData.courier_phone}
            />
          </div>
          {/* {market && (
            <div className={`input-wrap ${isTab1Disabled && "requerid-filed"}`}>
              <span>Список собственика </span>
              <Select
                className="input-style"
                defaultValue={selectedDdiagnostics}
                value={selectedDdiagnostics}
                noOptionsMessage={customNoOptionsMessage}
                options={auto_diagnostics("attribute_diagnostics")}
                styles={selectStyle}
                placeholder="Не указано"
                onChange={handleInputChange}
              />
            </div>
          )} */}

          {!market && ["Admin", "Manager"].includes(user?.data?.role) && (
            <div className={`input-wrap ${isTab1Disabled && "requerid-filed"}`}>
              <span>Список компаний </span>
              <Select
                className="input-style"
                defaultValue={selectedCompany}
                value={selectedCompany}
                noOptionsMessage={customNoOptionsMessage}
                options={companiesArray}
                styles={selectStyle}
                placeholder="Не указано"
                onChange={handleInputChange}
              />
            </div>
          )}
        </div>
      </div>
      {/*  */}

      {/* Параметры аукциона*/}
      {market ? (
        <div className="add-car-data__item">
          <h4>Атрибуты</h4>
          <div className="add-car-data__flex input-tow-col">
            <div className={`input-wrap ${isTab1Disabled && "requerid-filed"}`}>
              <span>Диагностика</span>
              <Select
                className="input-style"
                defaultValue={selectedDdiagnostics}
                value={selectedDdiagnostics}
                noOptionsMessage={customNoOptionsMessage}
                options={auto_diagnostics("attribute_diagnostics")}
                styles={selectStyle}
                placeholder="Не указано"
                onChange={handleInputChange}
              />
            </div>

            <div className={`input-wrap ${isTab1Disabled && "requerid-filed"}`}>
              <span>Место сделки</span>
              <Select
                className="input-style"
                defaultValue={selectedTransaction}
                value={selectedTransaction}
                noOptionsMessage={customNoOptionsMessage}
                options={auto_transaction}
                styles={selectStyle}
                placeholder="Не указано"
                onChange={handleInputChange}
              />
            </div>

            <div className={`input-wrap`}>
              <span>Юр. проблемы</span>
              <Select
                className="input-style"
                noOptionsMessage={customNoOptionsMessage}
                defaultValue={selectedLegal}
                value={selectedLegal}
                options={auto_legal}
                styles={selectStyle}
                placeholder="Не указано"
                onChange={handleInputChange}
                isMulti
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="add-car-data__item">
          <h4>Параметры аукциона</h4>
          <div className="add-car-data__flex add-car-data__search">
            {/* <div className={`input-wrap ${isTab1Disabled && "requerid-filed"}`}>
              <span>Дата окончания торгов</span>

              <div className="datapicker-width">
                <DatePicker
                  selected={createData.end_auction}
                  onChange={(date) =>
                    handleInputChange({ name: "end_auction", value: date })
                  }
                  timeInputLabel="Time:"
                  locale="ru"
                  dateFormat="dd.MM.yyyy HH:mm"
                  className="input-style w-100"
                  calendarStartDay={1}
                  showTimeInput
                  minDate={new Date()}
                />
              </div>
            </div> */}
            <div className={`input-wrap ${isTab1Disabled && "requerid-filed"}`}>
              <span>Дата окончания торгов</span>
              <div className="datapicker-width">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  locale="ru"
                  dateFormat="dd.MM.yyyy"
                  className="input-style w-100"
                  calendarStartDay={1}
                  placeholderText="Выберите дату"
                  minDate={addDays(new Date(), 1)}
                  defaultValue={createData.end_auction}
                />
              </div>
            </div>
            <div className={`input-wrap ${isTab1Disabled && "requerid-filed"}`}>
              <span>Время окончания торгов</span>
              <div className="datapicker-width">
                <DatePicker
                  selected={selectedTime}
                  onChange={handleTimeChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  locale="ru"
                  timeCaption="Время"
                  dateFormat="HH:mm"
                  className="input-style w-100"
                  placeholderText="Выберите время"
                  defaultValue={createData.end_auction}
                />
              </div>
            </div>
            <div
              className={`input-wrap ${isTab1Disabled && "requerid-filed"}`}
              data-after-content-input="дней"
            >
              <span>Срок обязывающего предложения</span>
              <input
                type="number"
                className="input-style"
                placeholder="Не указано"
                name="binding_offer"
                onChange={handleInputChange}
                defaultValue={createData.binding_offer}
              />
            </div>
          </div>
        </div>
      )}

      {/*  */}

      {/* Регион размещения */}
      <div className="add-car-data__item">
        <h4>Регион размещения</h4>
        <SearchCity
          createData={createData}
          isTab1Disabled={isTab1Disabled}
          name="region_id"
          onChangeCity={onChangeCity}
          title="Город размещения авто"
        />
      </div>
      {/*  */}

      {/* Стоимость */}
      {market ? (
        <div className="add-car-data__item">
          <h4>Стоимость</h4>
          <div className="add-car-data__flex input-tow-col">
            <div className="input-wrap">
              <span>Цена поставщика для дилера</span>
              <input
                type="text"
                className="input-style price-input"
                name="price"
                onChange={handleInputChange}
                value={
                  createData.price
                    ? createData.price.toLocaleString("en-US")
                    : ""
                }
              />
            </div>
            <div className="input-wrap w-25">
              <span>Цена для PRO</span>
              <input
                type="text"
                className="input-style price-input"
                name="price_discount"
                onChange={handleInputChange}
                value={
                  createData.price_discount
                    ? createData.price_discount.toLocaleString("en-US")
                    : 0
                }
              />
            </div>
            <div className="input-wrap w-25">
              <span>Цена для всех</span>
              <input
                type="text"
                className="input-style price-input"
                name="price_alls"
                onChange={handleInputChange}
                value={
                  createData.price_alls
                    ? createData.price_alls.toLocaleString("en-US")
                    : 0
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="add-car-data__item">
          <h4>Стоимость</h4>
          <div className="add-car-data__flex input-tow-col">
            <div className="input-wrap">
              <span>Стоимость ремонта ТС</span>
              <input
                type="text"
                className="input-style price-input"
                name="restoration_price"
                onChange={handleInputChange}
                value={
                  createData.restoration_price
                    ? createData.restoration_price.toLocaleString("en-US")
                    : ""
                }
              />
            </div>
            <div className="input-wrap w-25">
              <span>Рекомендованная стоимость реализации</span>
              <input
                type="text"
                className="input-style price-input"
                name="recommended_price_gots"
                onChange={handleInputChange}
                value={
                  createData.recommended_price_gots
                    ? createData.recommended_price_gots.toLocaleString("en-US")
                    : 0
                }
              />
            </div>
          </div>
        </div>
      )}

      {/*  */}

      {/* disablet-gradinet-btn */}
      <button
        type="button"
        className={`gradient-btn-blue `}
        onClick={onClickTab}
      >
        Продолжить
      </button>
    </div>
  );
}

export default AutoParameters;
