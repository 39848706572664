import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import List from "../../../components/chat/chat-list/list";
import Header from "../../../components/header/header";
import Preloader from "../../../components/prelouder/prelouder";
import Sidebar from "../../../components/sidebar/sidebar";
import { applicationsSingle } from "../../../store/applications/applicationsSingleSlice/applicationsSingleSlice";
import { chatsListManegerSingle } from "../../../store/chat/chatListMenegerSingleSlice/chatListMenegerSingleSlice";
import { chatsList } from "../../../store/chat/chatListSlice/chatListSlice";
import { chatGet } from "../../../store/chat/сhatIndexSlice/chatIndexSlice";
import ChatCreate from "../chat-create/chat-create";

function ChatIndex() {
  const { list, isLoading } = useSelector((state) => state.listChats);
  const [activePage, setActivePage] = useState(0);
  const { manager_single_chat } = useSelector(
    (state) => state.singleManagerChat
  );
  const { user } = useSelector((state) => state.profileGet);

  const { car_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (car_id) {
      const fetchChat = async () => {
        if (["Admin", "Manager"].includes(user?.data?.role)) {
          const res = await dispatch(chatsListManegerSingle(car_id));
          if (res.payload.status === 200) {
            dispatch(applicationsSingle(res?.data?.model_id));
          }
        } else {
          const res = await dispatch(chatGet(car_id));
          if (res.payload.status === 200) {
            dispatch(applicationsSingle(car_id));
          }
        }
      };

      fetchChat();
    }
    dispatch(chatsList({ page: 1 }));
  }, [car_id, dispatch, user]);

  const handlePageChange = (selectedObject) => {
    const newPage = selectedObject.selected;
    setActivePage(newPage);
    dispatch(
      chatsList({
        page: selectedObject.selected + 1,
      })
    );
    window.scrollTo(0, 0);
  };

  return (
    <Sidebar>
      <div className="main__data">
        <Header
          title="Обсуждения"
          subtitle={`Здесь все чаты по автомобилям, по которым вы проявили активность`}
        />
        <div className="chat-collumn">
          {(Object.keys(list).length !== 0 && list.data.length !== 0) ||
          (manager_single_chat && manager_single_chat.data.length !== 0) ? (
            <>
              <List list={list?.data} />
              {car_id && <ChatCreate />}
            </>
          ) : (
            <Preloader />
          )}
        </div>
        <div className="mt-3">
          <ReactPaginate
            pageCount={list?.data?.last_page}
            forcePage={activePage} // Здесь передаем текущее значение активной страницы
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            breakClassName={"pagination__item"}
            nextLinkClassName={"pagination__link"}
            nextLabel={`>`}
            previousLabel={`<`}
            pageClassName={"pagination__item"}
            disabledClassNae={"disabled"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </Sidebar>
  );
}

export default ChatIndex;
