import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Header from "../../../../components/header/header";
import PhoneWithFlags from "../../../../components/phone-with-flags/phone-with-flags";
import Sidebar from "../../../../components/sidebar/sidebar";
import { usersCreateGet } from "../../../../store/admin/users/userCreateGetSlice/userCreateGetSlice";
import { userStore } from "../../../../store/admin/users/userStoreSlice/userStoreSlice";
import { customNoOptionsMessage } from "../../../../utils/option-not-found";
import { selectCompany, selectRoles } from "../../../../utils/select-array";
import selectStyle from "../../../../utils/select-styles";

function AdminCreateUser() {
  const { data } = useSelector((state) => state.userCreateData);
  const [formData, setFormData] = useState({ name: "User" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(usersCreateGet());
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(userStore(formData)).then((res) => {
      if (res.payload.status === 200) {
        goBack();
        toast.success("Пользователь успешно создан !");
      } else {
        toast.error(res.payload.message);
      }
    });
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target ? e.target : e;
    setFormData({ ...formData, [name]: value });
  };

  console.log(formData);

  return (
    <Sidebar>
      <div className="main__data">
        <Header title="Добавление пользователя">
          <div onClick={goBack} className="main__data-head-back cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.05006 9.40834L11.7667 4.70001C11.8442 4.6219 11.9364 4.5599 12.0379 4.5176C12.1395 4.47529 12.2484 4.45351 12.3584 4.45351C12.4684 4.45351 12.5773 4.47529 12.6789 4.5176C12.7804 4.5599 12.8726 4.6219 12.9501 4.70001C13.1053 4.85614 13.1924 5.06735 13.1924 5.2875C13.1924 5.50766 13.1053 5.71887 12.9501 5.87501L8.82506 10.0417L12.9501 14.1667C13.1053 14.3228 13.1924 14.534 13.1924 14.7542C13.1924 14.9743 13.1053 15.1855 12.9501 15.3417C12.8729 15.4204 12.7808 15.4831 12.6793 15.526C12.5777 15.5689 12.4686 15.5912 12.3584 15.5917C12.2481 15.5912 12.1391 15.5689 12.0375 15.526C11.9359 15.4831 11.8439 15.4204 11.7667 15.3417L7.05006 10.6333C6.96547 10.5553 6.89796 10.4606 6.85179 10.3552C6.80562 10.2498 6.78178 10.1359 6.78178 10.0208C6.78178 9.90575 6.80562 9.79192 6.85179 9.6865C6.89796 9.58108 6.96547 9.48637 7.05006 9.40834Z"
                fill="#8D8796"
              />
            </svg>
          </div>
        </Header>
        <div className="settings__item w-100 mt-4">
          <div className="settings__item-title">Информация о пользователе</div>
          <form onSubmit={onSubmit}>
            {/* Информация о пользователе  */}
            <div className="input-tow-col add-car-data__flex ">
              {/* Имя */}
              <div className="input-wrap ">
                <span>Имя</span>
                <input
                  type="text"
                  name="name"
                  className="input-style "
                  placeholder="Не указано"
                  onChange={handleChange}
                />
              </div>
              {/*  Роль */}
              <div className="input-wrap ">
                <span>
                  Роль<span className="red-gradient-text">*</span>
                </span>

                <Select
                  className="input-style"
                  noOptionsMessage={customNoOptionsMessage}
                  options={selectRoles(data?.data?.roles, "role")}
                  styles={selectStyle}
                  onChange={handleChange}
                  placeholder="Не указано"
                  required
                />
              </div>
              {/* Телефон */}
              <div className="input-wrap ">
                <span className="position-absolute">
                  Телефон<span className="red-gradient-text">*</span>
                </span>
                <div className="position-relative">
                  <PhoneWithFlags onChange={handleChange} />
                </div>
              </div>
              {/* E-Mail */}
              <div className="input-wrap ">
                <span>E-Mail</span>
                <input
                  type="email"
                  name="email"
                  className="input-style "
                  placeholder="Не указано"
                  onChange={handleChange}
                />
              </div>
              {/* Срок подписки */}
              {/* <div className="input-wrap ">
                <span>Срок подписки</span>
                <input
                  type="text"
                  name="subscription"
                  className="input-style "
                  placeholder="Не указано"
                />
              </div> */}
              {/* Юридическая компания */}
              <div className="input-wrap ">
                <span>Юридическая компания</span>
                <Select
                  className="input-style"
                  noOptionsMessage={customNoOptionsMessage}
                  options={selectCompany(
                    data?.data?.insurance_companies,
                    "insurance_companies_id"
                  )}
                  styles={selectStyle}
                  name="insurance_companies_id"
                  placeholder="Не указано"
                  onChange={handleChange}
                />
              </div>
              {/* Сумма депозита */}
              <div className="input-wrap ">
                <span>Сумма депозита</span>
                <input
                  type="text"
                  name="deposit_sum"
                  className="input-style "
                  placeholder="Не указано"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-100 add-car-data__flex mt-4 add-car-data__item">
              <div className="w-25 ">
                <h5 className="settings__item-title">Отключить пользователя</h5>
                <div className="add-car-data__column">
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="status"
                      value={true}
                      onChange={handleChange}
                    />
                    <span></span>
                    Да, отключить
                  </label>
                  <label className="custom-radio mt-2">
                    <input
                      type="radio"
                      name="status"
                      value={false}
                      onChange={handleChange}
                    />
                    <span></span>
                    Не отключать
                  </label>
                </div>
              </div>
              <div className="w-25 ">
                <h5 className="settings__item-title">Доступ к аукциону</h5>
                <div className="add-car-data__column">
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="seller"
                      value="Есть"
                      onChange={handleChange}
                    />
                    <span></span>
                    Есть
                  </label>
                  <label className="custom-radio mt-2">
                    <input
                      type="radio"
                      name="seller"
                      value="Нет"
                      onChange={handleChange}
                    />
                    <span></span>
                    Нет
                  </label>
                </div>
              </div>
              <div className="w-25 ">
                <h5 className="settings__item-title">Доступ к маркету</h5>
                <div className="add-car-data__column">
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="marketAccess"
                      value="Нет"
                      onChange={handleChange}
                    />
                    <span></span>
                    Нет
                  </label>
                  <label className="custom-radio mt-2">
                    <input
                      type="radio"
                      name="marketAccess"
                      value="Есть"
                      onChange={handleChange}
                    />
                    <span></span>
                    Есть
                  </label>
                </div>
              </div>
              <div className="w-25 ">
                <h5 className="settings__item-title">Депозит</h5>
                <div className="add-car-data__column">
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="deposit"
                      value="Есть"
                      onChange={handleChange}
                    />
                    <span></span>
                    Есть
                  </label>
                  <label className="custom-radio mt-2">
                    <input
                      type="radio"
                      name="deposit"
                      value="Нет"
                      onChange={handleChange}
                    />
                    <span></span>
                    Нет
                  </label>
                </div>
              </div>
            </div>
            {/*  */}
            {/* Изменение пароля */}
            <div className="w-100 mt-4 add-car-data__item">
              <div className="settings__item-title">Изменение пароля</div>
              <div className="add-car-data__flex">
                <div className="input-wrap w-25 ml-0">
                  <span>Пароль</span>
                  <input
                    type="password"
                    name="password"
                    className="input-style "
                    placeholder="Не указано"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-wrap w-25">
                  <span>Подтверждение пароля</span>
                  <input
                    type="password"
                    name="password_confirmation"
                    className="input-style "
                    placeholder="Не указано"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
            {/*  */}
            {/* Личная информация */}
            <div className="w-100 mt-4 add-car-data__item">
              <div className="settings__item-title">Личная информация</div>
              <div className="add-car-data__flex">
                <div className="input-wrap w-25 ml-0">
                  <span>Фамилия</span>
                  <input
                    type="text"
                    name="last_name"
                    className="input-style "
                    placeholder="Не указано"
                    onChange={handleChange}
                  />
                </div>
                <div className="input-wrap w-25">
                  <span>Отчество</span>
                  <input
                    type="text"
                    name="middle_name"
                    className="input-style "
                    placeholder="Не указано"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              name="save"
              className={`gradient-btn-blue admin-btn-padding ml-0`}
            >
              {/* {isLoading ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Идет проверка...</span>
                </>
              ) : ( */}
              Добавить
              {/* )} */}
            </button>
          </form>
        </div>
      </div>
    </Sidebar>
  );
}

export default AdminCreateUser;
