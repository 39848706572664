import React from "react";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../../utils/format-price";

function Price({ item }) {
  const { user } = useSelector((state) => state.profileGet);
  console.log(item);

  const renderContent = () => {
    switch (user?.data?.role) {
      case "Pro": {
        let price = "";
        if (item?.price_alls !== 0) {
          price = (
            <>
              <div className="card__item-price-title">Стоимость авто:</div>
              <div className="card__item-price">
                {formatNumber(item?.price_alls)} ₽
              </div>
              {item?.price_discount !== 0 && (
                <>
                  <div className="card__item-supplier-title">Цена для PRO</div>
                  <div className="card__item-supplier-price">
                    {formatNumber(item?.price_discount)}₽
                  </div>
                </>
              )}
            </>
          );
        }

        if (item?.price_alls == 0) {
          price = (
            <>
              {item?.price_discount !== 0 && (
                <>
                  <div className="card__item-price-title">Стоимость авто:</div>
                  <div className="card__item-price">
                    {formatNumber(item?.price_discount)} ₽
                  </div>
                </>
              )}
            </>
          );
        }
        return price;
      }
      case "Dealer": {
        let price = "";
        if (item?.price_alls !== 0) {
          price = (
            <>
              <div className="card__item-price-title">Стоимость авто:</div>
              <div className="card__item-price">
                {formatNumber(item?.price_alls)} ₽
              </div>
              {item?.price_discount !== 0 && (
                <>
                  <div className="card__item-supplier-title">Цена для PRO</div>
                  <div className="card__item-supplier-price">
                    {formatNumber(item?.price_discount)} ₽
                  </div>
                </>
              )}

              {item?.price !== 0 && (
                <>
                  <div className="card__item-supplier-title">
                    Цена поставщика
                  </div>
                  <div className="card__item-supplier-price">
                    {formatNumber(item?.price)} ₽
                  </div>
                </>
              )}
            </>
          );
        }

        if (item?.price_alls == 0) {
          price = (
            <>
              {item?.price_discount !== 0 && (
                <>
                  <div className="card__item-price-title">Стоимость авто:</div>
                  <div className="card__item-price">
                    {formatNumber(item?.price_discount)} ₽
                  </div>
                  {item?.price !== 0 && (
                    <>
                      <div className="card__item-supplier-title">
                        Цена поставщика
                      </div>
                      <div className="card__item-supplier-price">
                        {formatNumber(item?.price)} ₽
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          );
        }

        return price;
      }
      case "User": {
        let price = "";
        if (item?.price_alls !== 0) {
          price = (
            <>
              <div className="card__item-price-title">Стоимость авто:</div>
              <div className="card__item-price">
                {formatNumber(item?.price_alls)} ₽
              </div>
            </>
          );
        }

        return price;
      }
      default: {
        let price = "";
        if (item?.price !== 0) {
          price = (
            <>
              <div className="card__item-price-title">Стоимость авто:</div>
              <div className="card__item-price">
                {formatNumber(item?.price)} ₽
              </div>
            </>
          );
        }

        return price;
      }
    }
  };

  return renderContent();
}

export default Price;
