import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import left_arrow from "../../../images/left-arrow.svg";
import noimage from "../../../images/no-image.png";
import { truncateString } from "../../../utils/text-slice";

function List({ list }) {
  const { car_id } = useParams();
  const { user } = useSelector((state) => state.profileGet);
  const { single } = useSelector((state) => state.applicationsSingle);

  return (
    <div className="chat-collumn-one">
      {list &&
        Object.keys(list).length !== 0 &&
        list.data.map((item) => {
          return (
            <Link
              to={`/discussion/${
                ["Admin", "Manager"].includes(user?.data?.role)
                  ? item.chat_room_id
                  : item.model_id
              }`}
              className={`chat-list-message d-flex align-items-center position-relative ${
                car_id ==
                (["Admin", "Manager"].includes(user?.data?.role)
                  ? item.chat_room_id
                  : item.model_id)
                  ? "active"
                  : ""
              }   ${item.status_id == 4 && "disabled"} `}
            >
              <div className="chat-list-content">
                <div className="chat__header">
                  <div>
                    {item?.thumbnail ? (
                      <img src={item?.thumbnail} alt="" />
                    ) : (
                      <img src={noimage} className="row__img mt-0" />
                    )}
                  </div>
                  <div className="chat__header--title">
                    <div>{/* {user?.messages[0].} */}</div>
                    <span>{item?.title}</span>
                  </div>
                </div>

                {item.status_id == 4 ? (
                  <div className="chat__text">Авто снято с продажи</div>
                ) : (
                  <div className="chat__text">
                    {item?.messages[0]?.role} :
                    {truncateString(item.messages[0].content, 125)}
                  </div>
                )}

                <span className="message-time">
                  {item.messages[0].created_at}
                </span>
              </div>
              <div className="d-flex justify-content-center chat-list-arrow">
                {item.unread_count !== 0 && (
                  <div className="chat-list-seen"></div>
                )}

                <img src={left_arrow} alt="" />
              </div>
            </Link>
          );
        })}
    </div>
  );
}

export default List;
