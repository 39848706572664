import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  applicationsBrand,
  brandReset,
} from "../../../store/applications-select/application-brand/applicationBrandSlice";
import {
  applicationsGenerations,
  generationReset,
} from "../../../store/applications-select/application-generations/applicationGenerationsSlice";
import {
  applicationsModel,
  modelReset,
} from "../../../store/applications-select/application-model/applicationModelSlice";
import {
  applicationsSeries,
  seriesReset,
} from "../../../store/applications-select/application-series/applicationSeriesSlice";
import { applicationsType } from "../../../store/applications-select/application-type/applicationTypeSlice";
import {
  applicationsYear,
  yearReset,
} from "../../../store/applications-select/application-year/applicationYearSlice";
import {
  applicationsEngines,
  enginesReset,
} from "../../../store/applications-select/applications-engines/applicationEnginesSlice";
import {
  applicationsGears,
  gearsReset,
} from "../../../store/applications-select/applications-gears/applicationGearsSlice";
import {
  applicationsModifications,
  modificationsReset,
} from "../../../store/applications-select/applications-modification/applicationModificationsSlice";
import {
  applicationsTransmissions,
  transmissionsReset,
} from "../../../store/applications-select/applications-transmission/applicationTransmissionsSlice";
import FileUploadFormEdit from "../../file-upload/file-edit-upload";
import FileUploadForm from "../../file-upload/file-upload";
import ImageUploaderEdit from "../../image-upload/image-edit-upload";
import ImageUploader from "../../image-upload/image-upload";

function AutoInfo({
  setCreateData,
  createData,
  keyTab,
  isTab2Disabled,
  editId,
  report,
  setAttachmentOrder,
  image,
  market,
}) {
  const dispatch = useDispatch();
  const { appType } = useSelector((state) => state.applicationType);
  const { brand } = useSelector((state) => state.applicationBrand);
  const { model } = useSelector((state) => state.applicationModel);
  const { year } = useSelector((state) => state.applicationYear);
  const { generations } = useSelector((state) => state.applicationGenerations);
  const { series } = useSelector((state) => state.applicationSeries);
  const { modifications } = useSelector(
    (state) => state.applicationModifications
  );
  const { engines } = useSelector((state) => state.applicationEngines);
  const { transmissions } = useSelector(
    (state) => state.applicationTransmissions
  );
  const { gears } = useSelector((state) => state.applicationGears);

  const [type, setType] = useState(createData?.car_type_id);
  const [brandActive, setBrand] = useState(createData?.car_mark_id);
  const [modelActive, setModel] = useState(createData.car_model_id);
  const [yearActive, setYear] = useState(createData.year);
  const [generationsActive, setGenerations] = useState(
    createData.car_generation_id
  );
  const [seriesActive, setSeries] = useState(createData.car_series_id);
  const [modificationActive, setModification] = useState(
    createData.car_modification_id
  );
  const [enginesActive, setEngines] = useState(createData.car_engine_id);
  const [transmissionsActive, setTransmissions] = useState(
    createData.car_transmission_id
  );
  const [gearsActive, setGears] = useState(createData.car_gear_id);

  const applicationTypeClick = (func) => {
    dispatch(func);
  };
  // Проверяем поколение и если оно пусто то выдаем кузов

  // Подтягиваем данные для редактирование
  useEffect(() => {
    if (editId) {
      const carTypeId = createData?.car_type_id;
      const carMarkId = createData?.car_mark_id;
      const carModelId = createData?.car_model_id;
      const carGenerationId = createData?.car_generation_id;
      const carSeriesId = createData?.car_series_id;
      const carModificationId = createData?.car_modification_id;

      // Сначала запрашиваем все необходимые данные, затем обновляем состояние
      Promise.all([
        dispatch(applicationsType()),
        dispatch(applicationsBrand(carTypeId)),
        dispatch(applicationsModel(carMarkId)),
        dispatch(applicationsYear(carModelId)),
        dispatch(
          applicationsGenerations({
            modelId: carModelId,
            yearId: createData.year,
          })
        ),
        dispatch(
          applicationsSeries({
            generetionId: carGenerationId,
            modelsId: carModelId,
          })
        ),
        dispatch(
          applicationsModifications({
            modelId: carModelId,
            seriesId: carSeriesId,
            yearId: createData.year,
          })
        ),
        dispatch(applicationsEngines(carModificationId)),
        dispatch(applicationsTransmissions(carModificationId)),
        dispatch(applicationsGears(carModificationId)),
      ])
        .then(() => {
          // Устанавливаем значения в состояние только после успешного завершения всех диспатчей
          setType(carTypeId);
          setBrand(carMarkId);
          setModel(carModelId);
          setYear(createData.year);
          setGenerations(carGenerationId);
          setSeries(carSeriesId);
          setModification(carModificationId);
          setEngines(createData.car_engine_id);
          setTransmissions(createData.car_transmission_id);
          setGears(createData.car_gear_id);
        })
        .catch((error) => {
          console.error("Ошибка при загрузке данных:", error);
        });
    }
  }, [keyTab]);
  //
  useEffect(() => {
    // Если нету Поколение
    if (generations === 0) {
      dispatch(
        applicationsSeries({
          generetionId: generationsActive,
          modelsId: modelActive,
        })
      );
    }
    //
  }, [generationsActive]);
  //
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(applicationsType());
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target ? e.target : e;
    let newValue = value;
    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }
    setCreateData({
      ...createData,
      [name]: isNaN(newValue) ? newValue : parseInt(newValue),
    });

    if (name === "car_type_id" && value !== brandActive) {
      setBrand(0);
      setModel(0);
      setYear(0);
      setGenerations(0);
      setSeries(0);
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(brandReset());
      dispatch(modelReset());
      dispatch(yearReset());
      dispatch(generationReset());
      dispatch(seriesReset());
      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());

      setCreateData({
        ...createData,
        [name]: isNaN(newValue) ? newValue : parseInt(newValue),
        car_mark_id: null,
        car_model_id: null,
        year: null,
        car_generation_id: null,
        car_series_id: null,
        car_modification_id: null,
        car_engine_id: null,
        car_transmission_id: null,
        car_gear_id: null,
      });
    }

    if (name === "car_mark_id" && value !== brandActive) {
      setModel(0);
      setYear(0);
      setGenerations(0);
      setSeries(0);
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(yearReset());
      dispatch(generationReset());
      dispatch(seriesReset());
      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());
      setCreateData({
        ...createData,
        [name]: isNaN(newValue) ? newValue : parseInt(newValue),
        car_model_id: null,
        year: null,
        car_generation_id: null,
        car_series_id: null,
        car_modification_id: null,
        car_engine_id: null,
        car_transmission_id: null,
        car_gear_id: null,
      });
    }

    if (name === "car_model_id" && value !== modelActive) {
      setYear(0);
      setGenerations(0);
      setSeries(0);
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(generationReset());
      dispatch(seriesReset());
      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());

      setCreateData({
        ...createData,
        [name]: isNaN(newValue) ? newValue : parseInt(newValue),
        year: null,
        car_generation_id: null,
        car_series_id: null,
        car_modification_id: null,
        car_engine_id: null,
        car_transmission_id: null,
        car_gear_id: null,
      });
    }

    if (name === "year" && value !== yearActive) {
      setGenerations(0);
      setSeries(0);
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(seriesReset());
      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());

      setCreateData({
        ...createData,
        [name]: isNaN(newValue) ? newValue : parseInt(newValue),
        car_generation_id: null,
        car_series_id: null,
        car_modification_id: null,
        car_engine_id: null,
        car_transmission_id: null,
        car_gear_id: null,
      });
    }

    if (name === "car_generation_id" && value !== generationsActive) {
      setSeries(0);
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());

      setCreateData({
        ...createData,
        [name]: isNaN(newValue) ? newValue : parseInt(newValue),
        car_series_id: null,
        car_modification_id: null,
        car_engine_id: null,
        car_transmission_id: null,
        car_gear_id: null,
      });
    }

    if (name === "car_series_id" && value !== seriesActive) {
      setModification(0);
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());

      setCreateData({
        ...createData,
        [name]: isNaN(newValue) ? newValue : parseInt(newValue),
        car_modification_id: null,
        car_engine_id: null,
        car_transmission_id: null,
        car_gear_id: null,
      });
    }

    if (name === "car_modification_id" && value !== modificationActive) {
      setEngines(0);
      setTransmissions(0);
      setGears(0);

      dispatch(transmissionsReset());
      dispatch(gearsReset());

      setCreateData({
        ...createData,
        [name]: isNaN(newValue) ? newValue : parseInt(newValue),
        car_engine_id: null,
        car_transmission_id: null,
        car_gear_id: null,
      });
    }

    if (name === "car_engine_id" && value !== enginesActive) {
      setTransmissions(0);
      setGears(0);

      dispatch(gearsReset());

      setCreateData({
        ...createData,
        [name]: isNaN(newValue) ? newValue : parseInt(newValue),
        car_transmission_id: null,
        car_gear_id: null,
      });
    }

    if (name === "car_transmission_id" && value !== transmissionsActive) {
      setGears(0);

      setCreateData({
        ...createData,
        [name]: isNaN(newValue) ? newValue : parseInt(newValue),
        car_gear_id: null,
      });
    }
  };

  // Делаем проверку на количество и если одно то запускаем код

  // // Модель
  useEffect(() => {
    // Если есть одно поколение
    if (keyTab === market ? "auto-tab-2" : "seller-tab-2") {
      if (model) {
        if (model.length === 1) {
          const item = model[0]; // Получаем первый и единственный элемент массива model
          const modelsId = item.id;
          setModel(item.id);
          dispatch(applicationsYear(modelsId));
          setCreateData({
            ...createData,
            car_model_id: isNaN(modelsId) ? modelsId : parseInt(modelsId),
          });
        }
      }
    }
  }, [model]);
  //

  // Год
  useEffect(() => {
    // Если есть одно поколение
    if (keyTab === market ? "auto-tab-2" : "seller-tab-2") {
      if (year) {
        if (year.length === 1) {
          const item = year[0]; // Получаем первый и единственный элемент массива year
          const modelId = modelActive;
          const yearId = item.id;
          dispatch(applicationsGenerations({ modelId, yearId }));
          setCreateData({
            ...createData,
            year: isNaN(yearId) ? yearId : parseInt(yearId),
          });
        }
      }
    }
  }, [year]);
  //

  // ПОКОЛЕНИЕ
  useEffect(() => {
    // Если есть одно поколение
    if (keyTab === market ? "auto-tab-2" : "seller-tab-2") {
      if (generations) {
        if (generations.length === 1) {
          const item = generations[0]; // Получаем первый и единственный элемент массива generations
          const generetionId = item.id;
          const modelsId = modelActive;
          dispatch(applicationsSeries({ generetionId, modelsId }));
          setCreateData({
            ...createData,
            car_generation_id: isNaN(generetionId)
              ? generetionId
              : parseInt(generetionId),
          });
        }
      }
    }
  }, [generations]);
  //

  // КУЗОВ
  useEffect(() => {
    // Если есть одно поколение
    if (keyTab === market ? "auto-tab-2" : "seller-tab-2") {
      if (series) {
        if (series.length === 1) {
          const item = series[0]; // Получаем первый и единственный элемент массива generations
          const modelId = modelActive;
          const seriesId = item.id;
          const yearId = yearActive;
          dispatch(applicationsModifications({ modelId, seriesId, yearId }));
          setCreateData({
            ...createData,
            car_series_id: isNaN(seriesId) ? seriesId : parseInt(seriesId),
          });
        }
      }
    }
  }, [series]);
  //

  // МОДИФИКАЦИЯ
  useEffect(() => {
    // Если есть одно поколение
    if (keyTab === market ? "auto-tab-2" : "seller-tab-2") {
      if (modifications) {
        if (modifications.length === 1) {
          const item = modifications[0]; // Получаем первый и единственный элемент массива generations
          setModification(item.id);

          dispatch(applicationsEngines(item.id));
          setCreateData({
            ...createData,
            car_modification_id: isNaN(item.id) ? item.id : parseInt(item.id),
          });
        }
      }
    }
  }, [modifications]);
  //
  // ДВИГАТЕЛЬ
  useEffect(() => {
    // Если есть одно поколение
    if (keyTab === market ? "auto-tab-2" : "seller-tab-2") {
      if (engines) {
        if (engines.length === 1) {
          const item = engines[0];
          const enginesID = item.id;
          dispatch(applicationsTransmissions(modificationActive));
          setCreateData({
            ...createData,
            car_engine_id: isNaN(enginesID) ? enginesID : parseInt(enginesID),
          });
        }
      }
    }
  }, [engines, modificationActive]);
  //
  // КПП
  useEffect(() => {
    // Если есть одно поколение
    if (keyTab === market ? "auto-tab-2" : "seller-tab-2") {
      if (transmissions) {
        if (transmissions.length === 1) {
          const item = transmissions[0];
          const transmissionsId = item.id;
          dispatch(applicationsGears(modificationActive));
          setCreateData({
            ...createData,
            car_transmission_id: isNaN(transmissionsId)
              ? transmissionsId
              : parseInt(transmissionsId),
          });
        }
      }
    }
  }, [transmissions]);
  //

  // Привод
  useEffect(() => {
    // Если есть одно поколение
    if (keyTab === market ? "auto-tab-2" : "seller-tab-2") {
      if (gears) {
        if (gears.length === 1) {
          const item = gears[0];
          const gearsId = item.id;
          setCreateData({
            ...createData,
            car_gear_id: isNaN(gearsId) ? gearsId : parseInt(gearsId),
          });
        }
      }
    }
  }, [gears]);
  //
  // //

  // Тех.состояние
  const [checkboxStates, setCheckboxStates] = useState({
    condition_engine: createData?.condition_engine || [],
    condition_transmission: createData?.condition_transmission || [],
    condition_electric: createData?.condition_electric || [],
    condition_gear: createData?.condition_gear || [],
  });

  const handleCheckboxChange = (name, value) => {
    setCheckboxStates((prevState) => {
      if (Array.isArray(prevState[name])) {
        // Если значение уже является массивом
        if (prevState[name].includes(value)) {
          // Удаляем значение из массива
          return {
            ...prevState,
            [name]: prevState[name].filter((item) => item !== value),
          };
        } else {
          // Добавляем значение в массив
          return {
            ...prevState,
            [name]: [...prevState[name], value],
          };
        }
      } else {
        // Если значение не является массивом
        return {
          ...prevState,
          [name]: [value],
        };
      }
    });
  };

  useEffect(() => {
    // Если есть одно поколение

    setCreateData({
      ...createData,
      ...checkboxStates,
    });
  }, [checkboxStates]);
  //

  // Очищаем Марка и модель поколение и тд. перед началом работы
  useEffect(() => {
    if (editId) {
      dispatch(brandReset());
      dispatch(modelReset());
      dispatch(yearReset());
      dispatch(generationReset());
      dispatch(seriesReset());
      dispatch(modificationsReset());
      dispatch(enginesReset());
      dispatch(transmissionsReset());
      dispatch(gearsReset());
    }
  }, [keyTab]);
  //
  const [searchType, setSearchType] = useState("");
  const [searchMark, setSearchMark] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [searchYear, setSearchYear] = useState("");

  const filterByType = (e) => {
    const { value } = e.target;
    setSearchType(value);
  };

  const filterByMark = (e) => {
    const { value } = e.target;
    setSearchMark(value);
  };

  const filterByModel = (e) => {
    const { value } = e.target;
    setSearchModel(value);
  };

  const filterByYear = (e) => {
    const { value } = e.target;
    setSearchYear(value);
  };

  console.log(createData);

  return (
    <>
      <div className="add-car-data">
        <h3>Информация об автомобиле</h3>
        {/* Марка и модель */}
        <div className="add-car-data__item">
          <h4>Марка и модель</h4>
          <div className="add-car-data__flex add-car-data__search">
            {/* Тип Авто */}
            <div
              className={`add-car-data__search-item ${
                !isTab2Disabled && "requerid-filed"
              }`}
            >
              <h5>Тип авто</h5>
              <div className="add-car-data__search-card">
                <input
                  type="text"
                  placeholder="Поиск"
                  className="add-car-data__search-input"
                  onChange={(e) => filterByType(e)}
                />
                <ul className="add-car-data__search-list">
                  {appType &&
                    appType
                      .filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(searchType.toLowerCase())
                      )
                      .map((item, index) => {
                        return (
                          <li
                            className={`add-car-data__search-list-item ${
                              type === item.id ? "active" : ""
                            }`}
                            key={index}
                            onClick={() => {
                              setType(item.id);
                              applicationTypeClick(applicationsBrand(item.id));
                              handleInputChange({
                                name: "car_type_id",
                                value: item.id,
                              });
                            }}
                          >
                            <div>{item.name}</div>
                          </li>
                        );
                      })}
                </ul>
              </div>
            </div>
            {/*  */}
            {/* Если ПРОЧЕЕ то убираем все поля для выборки и оставляем поля для ввода имени авто */}
            {type === 27 ? (
              <div className="col">
                <label htmlFor="reg_number">Заголовок для авто</label>
                <textarea
                  className="form-control mw-100"
                  rows="4"
                  name="car_title"
                  onChange={handleInputChange}
                >
                  {createData.car_title}
                </textarea>
              </div>
            ) : (
              <>
                {/* Марка авто */}
                <div
                  className={`add-car-data__search-item ${
                    !isTab2Disabled && "requerid-filed"
                  }`}
                >
                  <h5>Марка</h5>
                  <div className="add-car-data__search-card ">
                    <input
                      type="text"
                      placeholder="Поиск по списку"
                      className="add-car-data__search-input"
                      onChange={(e) => filterByMark(e)}
                    />
                    <ul className="add-car-data__search-list">
                      {brand && brand.length !== 0 ? (
                        brand
                          .filter((item) =>
                            item.name
                              .toLowerCase()
                              .includes(searchMark.toLowerCase())
                          )
                          .map((item, index) => {
                            return (
                              <li
                                className={`add-car-data__search-list-item  ${
                                  brandActive === item.id ? "active" : ""
                                }`}
                                key={index}
                                onClick={() => {
                                  setBrand(item.id);
                                  applicationTypeClick(
                                    applicationsModel(item.id)
                                  );
                                  handleInputChange({
                                    name: "car_mark_id",
                                    value: item.id,
                                  });
                                }}
                              >
                                <div>{item.name}</div>
                              </li>
                            );
                          })
                      ) : (
                        <div className="add-car-data__search-no-selected add-car-data__search-list">
                          Выберите тип
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
                {/*  */}

                {/* Модель авто */}
                <div className="add-car-data__search-item">
                  <h5>Модель</h5>
                  <div className="add-car-data__search-card">
                    <input
                      type="text"
                      placeholder="Поиск по списку"
                      className="add-car-data__search-input"
                      onChange={(e) => filterByModel(e)}
                    />
                    <ul
                      className="add-car-data__search-list"
                      data-placeholder="Выберите марку авто"
                    >
                      {model && model.length !== 0 ? (
                        model
                          .filter((item) =>
                            item.name
                              .toLowerCase()
                              .includes(searchModel.toLowerCase())
                          )
                          .map((item, index) => {
                            return (
                              <li
                                className={`add-car-data__search-list-item  ${
                                  modelActive === item.id || model.length === 1
                                    ? "active"
                                    : ""
                                }`}
                                key={index}
                                onClick={() => {
                                  setModel(item.id);
                                  applicationTypeClick(
                                    applicationsYear(item.id)
                                  );
                                  handleInputChange({
                                    name: "car_model_id",
                                    value: item.id,
                                  });
                                }}
                              >
                                <div>{item.name}</div>
                              </li>
                            );
                          })
                      ) : (
                        <div className="add-car-data__search-no-selected add-car-data__search-list">
                          Выберите марку
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
                {/*  */}

                {/* Год авто */}
                <div className="add-car-data__search-item">
                  <h5>Год выпуска</h5>
                  <div className="add-car-data__search-card">
                    <input
                      type="text"
                      placeholder="Поиск по списку"
                      className="add-car-data__search-input"
                      onChange={(e) => filterByYear(e)}
                    />
                    <ul className="add-car-data__search-list">
                      {year && year.length !== 0 ? (
                        year
                          .filter((item) =>
                            item.name
                              .toString()
                              .toLowerCase()
                              .includes(searchYear.toLowerCase())
                          )
                          .map((item, index) => {
                            return (
                              <li
                                className={`add-car-data__search-list-item ${
                                  yearActive === item.id || year.length === 1
                                    ? "active"
                                    : ""
                                }`}
                                key={index}
                                onClick={() => {
                                  setYear(item.id);
                                  applicationTypeClick(
                                    applicationsGenerations({
                                      modelId: modelActive,
                                      yearId: item.id,
                                    })
                                  );
                                  handleInputChange({
                                    name: "year",
                                    value: item.id,
                                  });
                                }}
                              >
                                <div>{item.name}</div>
                              </li>
                            );
                          })
                      ) : (
                        <div className="add-car-data__search-no-selected add-car-data__search-list">
                          Выберите модель
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
                {/*  */}
              </>
            )}
            {/*  */}
          </div>
        </div>
        {/*  */}

        {/* Поколение и модификация */}
        {type === 27 ? (
          ""
        ) : (
          <div className="add-car-data__item">
            <h4>Поколение и модификация</h4>
            <div className="add-car-data__flex add-car-data__search">
              {/* Поколение  */}
              <div className="add-car-data__search-item">
                <h5>Поколение</h5>
                <div className="add-car-data__search-card">
                  <input
                    type="text"
                    placeholder="Поиск по списку"
                    className="add-car-data__search-input"
                  />
                  <ul className="add-car-data__search-list">
                    {generations &&
                    generations.length !== 0 &&
                    generations !== 0 ? (
                      generations.map((item, index) => {
                        return (
                          <li
                            className={`add-car-data__search-list-item ${
                              generationsActive === item.id ||
                              generations.length === 1
                                ? "active"
                                : ""
                            }`}
                            key={index}
                            onClick={() => {
                              setGenerations(item.id);
                              applicationTypeClick(
                                applicationsSeries({
                                  generetionId: item.id,
                                  modelsId: modelActive,
                                })
                              );
                              handleInputChange({
                                name: "car_generation_id",
                                value: item.id,
                              });
                            }}
                          >
                            <div>{item.name}</div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="add-car-data__search-no-selected add-car-data__search-list">
                        Выберите год
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              {/*  */}

              {/* Кузов  */}

              <div className="add-car-data__search-item">
                <h5>Кузов</h5>
                <div className="add-car-data__search-card">
                  <input
                    type="text"
                    placeholder="Поиск по списку"
                    className="add-car-data__search-input"
                  />
                  <ul className="add-car-data__search-list">
                    {series && series.length !== 0 ? (
                      series.map((item, index) => {
                        return (
                          <li
                            className={`add-car-data__search-list-item ${
                              seriesActive === item.id || series.length === 1
                                ? "active"
                                : ""
                            }`}
                            key={index}
                            onClick={() => {
                              setSeries(item.id);
                              applicationTypeClick(
                                applicationsModifications({
                                  modelId: modelActive,
                                  seriesId: item.id,
                                  yearId: yearActive,
                                })
                              );
                              handleInputChange({
                                name: "car_series_id",
                                value: item.id,
                              });
                            }}
                          >
                            <div>{item.name}</div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="add-car-data__search-no-selected add-car-data__search-list">
                        Выберите поколение
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              {/*  */}

              {/* Модификация */}
              <div className="add-car-data__search-item">
                <h5>Модификация</h5>
                <div className="add-car-data__search-card">
                  <input
                    type="text"
                    placeholder="Поиск по списку"
                    className="add-car-data__search-input"
                  />
                  <ul className="add-car-data__search-list">
                    {modifications && modifications.length !== 0 ? (
                      modifications.map((item, index) => {
                        return (
                          <li
                            className={`add-car-data__search-list-item ${
                              modificationActive === item.id ||
                              modifications.length === 1
                                ? "active"
                                : ""
                            }`}
                            key={index}
                            onClick={() => {
                              setModification(item.id);
                              applicationTypeClick(
                                applicationsEngines(item.id)
                              );
                              handleInputChange({
                                name: "car_modification_id",
                                value: item.id,
                              });
                            }}
                          >
                            <div>{item.name}</div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="add-car-data__search-no-selected add-car-data__search-list">
                        Выберите кузов
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              {/*  */}

              {/* Двигатель */}
              <div className="add-car-data__search-item">
                <h5>Двигатель</h5>
                <div className="add-car-data__search-card">
                  <input
                    type="text"
                    placeholder="Поиск по списку"
                    className="add-car-data__search-input"
                  />
                  <ul
                    className="add-car-data__search-list"
                    data-placeholder="Выберите двигатель авто"
                  >
                    {engines && engines.length !== 0 ? (
                      engines.map((item, index) => {
                        return (
                          <li
                            className={`add-car-data__search-list-item ${
                              enginesActive === item.id || engines.length === 1
                                ? "active"
                                : ""
                            }`}
                            key={index}
                            onClick={() => {
                              setEngines(item.id);
                              applicationTypeClick(
                                applicationsTransmissions(modificationActive)
                              );
                              handleInputChange({
                                name: "car_engine_id",
                                value: item.id,
                              });
                            }}
                          >
                            <div>{item.name}</div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="add-car-data__search-no-selected">
                        Выберите модификацию
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              {/*  */}

              {/* КПП */}
              <div className="add-car-data__search-item">
                <h5>КПП</h5>
                <div className="add-car-data__search-card">
                  <input
                    type="text"
                    placeholder="Поиск по списку"
                    className="add-car-data__search-input"
                  />
                  <ul
                    className="add-car-data__search-list"
                    data-placeholder="Выберите двигатель авто"
                  >
                    {transmissions && transmissions.length !== 0 ? (
                      transmissions.map((item, index) => {
                        return (
                          <li
                            className={`add-car-data__search-list-item ${
                              transmissionsActive === item.id ||
                              transmissions.length === 1
                                ? "active"
                                : ""
                            }`}
                            key={index}
                            onClick={() => {
                              setTransmissions(item.id);
                              applicationTypeClick(
                                applicationsGears(modificationActive)
                              );
                              handleInputChange({
                                name: "car_transmission_id",
                                value: item.id,
                              });
                            }}
                          >
                            <div>{item.name}</div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="add-car-data__search-no-selected">
                        Выберите двигатель
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              {/*  */}

              {/* Привод  */}
              <div className="add-car-data__search-item">
                <h5>Привод</h5>
                <div className="add-car-data__search-card">
                  <input
                    type="text"
                    placeholder="Поиск по списку"
                    className="add-car-data__search-input"
                  />
                  <ul
                    className="add-car-data__search-list"
                    data-placeholder="Выберите двигатель авто"
                  >
                    {gears && gears.length !== 0 ? (
                      gears.map((item, index) => {
                        return (
                          <li
                            className={`add-car-data__search-list-item ${
                              gearsActive === item.id || gears.length === 1
                                ? "active"
                                : ""
                            }`}
                            key={index}
                            onClick={() => {
                              setGears(item.id);
                              applicationTypeClick(
                                applicationsTransmissions(modificationActive)
                              );
                              handleInputChange({
                                name: "car_gear_id",
                                value: item.id,
                              });
                            }}
                          >
                            <div>{item.name}</div>
                          </li>
                        );
                      })
                    ) : (
                      <div className="add-car-data__search-no-selected">
                        Выберите КПП
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        )}
        {/*  */}

        {/* Пробег */}
        <div className="add-car-data__item">
          <h5>Пробег</h5>
          <div className="add-car-data__flex add-car-data__search">
            <div className="input-wrap" data-after-content-input="км">
              <span>Пробег</span>
              <input
                type="number"
                name="milage"
                className="input-style "
                placeholder="Не указано"
                defaultValue={createData.milage}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        {/*  */}

        {/* Количество владельцев и Кол-во ключей */}
        <div className="add-car-data__item">
          <div className="add-car-data__row">
            <div className="col-6">
              <h5>Количество владельцев</h5>
              <div className="add-car-data__column">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="owner_number"
                    defaultValue={parseInt(1)}
                    defaultChecked={
                      createData.owner_number === 1 ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>
                  Первый
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="owner_number"
                    defaultValue={parseInt(2)}
                    defaultChecked={
                      createData.owner_number === 2 ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>
                  Второй
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="owner_number"
                    defaultValue={3}
                    defaultChecked={
                      createData.owner_number === 3 ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>
                  Третий и более
                </label>
              </div>
            </div>
            <div className="col-6">
              <h5>Кол-во ключей</h5>
              <div className="add-car-data__column">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="car_key_quantity"
                    defaultChecked={
                      createData.car_key_quantity === 0 ? true : false
                    }
                    defaultValue={0}
                    onChange={handleInputChange}
                  />
                  <span></span>
                  Неизвестно
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="car_key_quantity"
                    defaultValue={1}
                    defaultChecked={
                      createData.car_key_quantity === 1 ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>1 дубликат
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="car_key_quantity"
                    defaultValue={2}
                    defaultChecked={
                      createData.car_key_quantity === 2 ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>2 дубликата
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="car_key_quantity"
                    defaultValue={3}
                    defaultChecked={
                      createData.car_key_quantity === 3 ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>3 дубликата
                </label>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        {/* Авто заводится Неисправности */}
        <div className="add-car-data__item">
          <h5>Авто заводится?</h5>
          {/* Авто заводится */}
          <div className="add-car-data__row">
            <div className="col-3">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="starts_up"
                  defaultValue={"Да"}
                  defaultChecked={createData.starts_up === "Да" ? true : false}
                  onChange={handleInputChange}
                />
                <span></span>
                Да
              </label>
            </div>
            <div className="col-3">
              <label className="custom-radio">
                <input
                  type="radio"
                  name="starts_up"
                  defaultValue={"Нет"}
                  defaultChecked={createData.starts_up === "Нет" ? true : false}
                  onChange={handleInputChange}
                />
                <span></span>
                Нет
              </label>
            </div>
            <div className="col-3">
              <label className="custom-radio">
                <input
                  type="radio"
                  defaultValue={"Не пробовали"}
                  name="starts_up"
                  defaultChecked={
                    createData.starts_up === "Не пробовали" ? true : false
                  }
                  onChange={handleInputChange}
                />
                <span></span>
                Не проверяли
              </label>
            </div>
          </div>
          {/*  */}

          <h5>Неисправности</h5>
          <div className="add-car-data__row">
            {/* Неисправности двигателя */}
            <div className="col-3 add-car-data__ceheckbox-list">
              <label className="custom-checkbox custom-checkbox--square custom-checkbox--bold">
                <input
                  type="checkbox"
                  checked={checkboxStates.condition_engine.includes("null")}
                  onChange={() => {
                    if (checkboxStates.condition_engine.includes("null")) {
                      // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                      setCheckboxStates((prevState) => ({
                        ...prevState,
                        condition_engine: [],
                      }));
                    } else {
                      // Если основной чекбокс был снят, оставляем массив без изменений
                      setCheckboxStates((prevState) => ({
                        ...prevState,
                        condition_engine: ["null"],
                      }));
                    }
                  }}
                />
                <span></span>
                Неисправности двигателя
              </label>
              {checkboxStates.condition_engine.includes("null") && (
                <>
                  <label className="custom-checkbox custom-checkbox--square">
                    <input
                      type="checkbox"
                      checked={checkboxStates.condition_engine.includes(
                        "Дымность двигателя (густой, белый, сизый, черный)"
                      )}
                      defaultValue="Дымность двигателя (густой, белый, сизый, черный)"
                      onChange={() =>
                        handleCheckboxChange(
                          "condition_engine",
                          "Дымность двигателя (густой, белый, сизый, черный)"
                        )
                      }
                    />
                    <span></span>
                    Дымность двигателя (густой, белый, сизый, чёрный)
                  </label>
                  <label className="custom-checkbox custom-checkbox--square">
                    <input
                      type="checkbox"
                      checked={checkboxStates.condition_engine.includes(
                        "Повышенный стук и шум при работе двигателя"
                      )}
                      defaultValue="Повышенный стук и шум при работе двигателя"
                      onChange={() =>
                        handleCheckboxChange(
                          "condition_engine",
                          "Повышенный стук и шум при работе двигателя"
                        )
                      }
                    />
                    <span></span>
                    Повышенный стук и шум при работе двигателя
                  </label>
                  <label className="custom-checkbox custom-checkbox--square">
                    <input
                      type="checkbox"
                      checked={checkboxStates.condition_engine.includes(
                        "Повышенный шум при работе выхлопной системы"
                      )}
                      defaultValue="Повышенный шум при работе выхлопной системы"
                      onChange={() =>
                        handleCheckboxChange(
                          "condition_engine",
                          "Повышенный шум при работе выхлопной системы"
                        )
                      }
                    />
                    <span></span>
                    Повышенный шум при работе выхлопной системы
                  </label>
                  <label className="custom-checkbox custom-checkbox--square">
                    <input
                      type="checkbox"
                      checked={checkboxStates.condition_engine.includes(
                        "Подтекание при осмотре подкапотного пространства"
                      )}
                      onChange={() =>
                        handleCheckboxChange(
                          "condition_engine",
                          "Подтекание при осмотре подкапотного пространства"
                        )
                      }
                    />
                    <span></span>
                    Подтекание при осмотре подкапотного пространства
                  </label>
                </>
              )}
            </div>
            {/*  */}

            {/* Неисправности КПП */}
            <div className="col-3 add-car-data__ceheckbox-list">
              <label className="custom-checkbox custom-checkbox--square custom-checkbox--bold">
                <input
                  type="checkbox"
                  checked={checkboxStates.condition_transmission.includes(
                    "null"
                  )}
                  onChange={() => {
                    if (
                      checkboxStates.condition_transmission.includes("null")
                    ) {
                      // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                      setCheckboxStates((prevState) => ({
                        ...prevState,
                        condition_transmission: [],
                      }));
                    } else {
                      // Если основной чекбокс был снят, оставляем массив без изменений
                      setCheckboxStates((prevState) => ({
                        ...prevState,
                        condition_transmission: ["null"],
                      }));
                    }
                  }}
                />
                <span></span>
                Неисправности КПП
              </label>
              {checkboxStates.condition_transmission.includes("null") && (
                <>
                  <label className="custom-checkbox custom-checkbox--square">
                    <input
                      type="checkbox"
                      checked={checkboxStates.condition_transmission.includes(
                        "Рывки и толчки авто при переключении"
                      )}
                      defaultValue="Рывки и толчки авто при переключении"
                      onChange={() =>
                        handleCheckboxChange(
                          "condition_transmission",
                          "Рывки и толчки авто при переключении"
                        )
                      }
                    />
                    <span></span>
                    Рывки и толчки авто при переключении
                  </label>
                  <label className="custom-checkbox custom-checkbox--square">
                    <input
                      type="checkbox"
                      checked={checkboxStates.condition_transmission.includes(
                        "Повышенный шум при переключении"
                      )}
                      defaultValue="Повышенный шум при переключении"
                      onChange={() =>
                        handleCheckboxChange(
                          "condition_transmission",
                          "Повышенный шум при переключении"
                        )
                      }
                    />
                    <span></span>
                    Повышенный шум при переключении
                  </label>
                </>
              )}
            </div>
            {/*  */}

            {/* Неисправности электрики */}
            <div className="col-3 add-car-data__ceheckbox-list">
              <label className="custom-checkbox custom-checkbox--square custom-checkbox--bold">
                <input
                  type="checkbox"
                  checked={checkboxStates.condition_electric.includes("null")}
                  onChange={() => {
                    if (checkboxStates.condition_electric.includes("null")) {
                      // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                      setCheckboxStates((prevState) => ({
                        ...prevState,
                        condition_electric: [],
                      }));
                    } else {
                      // Если основной чекбокс был снят, оставляем массив без изменений
                      setCheckboxStates((prevState) => ({
                        ...prevState,
                        condition_electric: ["null"],
                      }));
                    }
                  }}
                />
                <span></span>
                Неисправности электрики
              </label>
              {checkboxStates.condition_electric.includes("null") && (
                <>
                  <label className="custom-checkbox custom-checkbox--square">
                    <input
                      type="checkbox"
                      checked={checkboxStates.condition_electric.includes(
                        "Ошибки на панели приборов при заведенном ДВС"
                      )}
                      defaultValue="Ошибки на панели приборов при заведенном ДВС"
                      onChange={() =>
                        handleCheckboxChange(
                          "condition_electric",
                          "Ошибки на панели приборов при заведенном ДВС"
                        )
                      }
                    />
                    <span></span>
                    Ошибки на панели приборов при заведенном ДВС
                  </label>
                  <label className="custom-checkbox custom-checkbox--square">
                    <input
                      type="checkbox"
                      checked={checkboxStates.condition_electric.includes(
                        "Неправильные команды электроники"
                      )}
                      defaultValue="Неправильные команды электроники"
                      onChange={() =>
                        handleCheckboxChange(
                          "condition_electric",
                          "Неправильные команды электроники"
                        )
                      }
                    />
                    <span></span>
                    Неправильные команды электроники
                  </label>
                </>
              )}
            </div>
            {/*  */}

            <div className="col-3 add-car-data__ceheckbox-list">
              <label className="custom-checkbox custom-checkbox--square custom-checkbox--bold">
                <input
                  type="checkbox"
                  checked={checkboxStates.condition_gear.includes("null")}
                  onChange={() => {
                    if (checkboxStates.condition_gear.includes("null")) {
                      // Если основной чекбокс был отмечен, очищаем массив и отключаем все чекбоксы
                      setCheckboxStates((prevState) => ({
                        ...prevState,
                        condition_gear: [],
                      }));
                    } else {
                      // Если основной чекбокс был снят, оставляем массив без изменений
                      setCheckboxStates((prevState) => ({
                        ...prevState,
                        condition_gear: ["null"],
                      }));
                    }
                  }}
                />
                <span></span>
                Неисправности ходовой
              </label>
              {checkboxStates.condition_gear.includes("null") && (
                <>
                  <label className="custom-checkbox custom-checkbox--square">
                    <input
                      type="checkbox"
                      checked={checkboxStates.condition_gear.includes(
                        "Посторонний звук со стороны ходовой"
                      )}
                      defaultValue="Посторонний звук со стороны ходовой"
                      onChange={() =>
                        handleCheckboxChange(
                          "condition_gear",
                          "Посторонний звук со стороны ходовой"
                        )
                      }
                    />
                    <span></span>
                    Посторонний звук со стороны ходовой
                  </label>
                  <label className="custom-checkbox custom-checkbox--square">
                    <input
                      type="checkbox"
                      checked={checkboxStates.condition_gear.includes(
                        "Посторонние звуки при вращении рулевого колеса"
                      )}
                      defaultValue="Посторонние звуки при вращении рулевого колеса"
                      onChange={() =>
                        handleCheckboxChange(
                          "condition_gear",
                          "Посторонние звуки при вращении рулевого колеса"
                        )
                      }
                    />
                    <span></span>
                    Посторонние звуки при вращении рулевого колеса
                  </label>
                </>
              )}
            </div>
          </div>
        </div>
        {/*  */}

        {/* Шины  Диски и тд.. */}
        <div className="add-car-data__item">
          <div className="add-car-data__row">
            <div className="col-3">
              <h5>Диски</h5>
              <div className="add-car-data__column">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="disks"
                    defaultValue={"Литые"}
                    defaultChecked={createData.disks === "Литые" ? true : false}
                    onChange={handleInputChange}
                  />
                  <span></span>
                  Литые
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="disks"
                    defaultValue={"Штампованные"}
                    defaultChecked={
                      createData.disks === "Штампованные" ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>
                  Штампованные
                </label>
              </div>
            </div>
            <div className="col-3">
              <h5>Шины</h5>
              <div className="add-car-data__column">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="tires"
                    defaultValue={"Летние"}
                    defaultChecked={
                      createData.tires === "Летние" ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>
                  Летние
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="tires"
                    defaultValue={"Зимние"}
                    defaultChecked={
                      createData.tires === "Зимние" ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>
                  Зимние
                </label>
              </div>
            </div>
            <div className="col-3">
              <h5>Износ шин</h5>
              <div className="add-car-data__column">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="tire_wire"
                    defaultValue={"до 25%"}
                    defaultChecked={
                      createData.tire_wire === "до 25%" ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>
                  До 25%
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="tire_wire"
                    defaultValue={"от 25% до 50%"}
                    defaultChecked={
                      createData.tire_wire === "от 25% до 50%" ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>
                  От 25% до 50%
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="tire_wire"
                    defaultValue={"свыше 50%"}
                    defaultChecked={
                      createData.tire_wire === "свыше 50%" ? true : false
                    }
                    onChange={handleInputChange}
                  />
                  <span></span>
                  Свыше 50%
                </label>
              </div>
            </div>
            <div className="col-3">
              <h5>Дополнительно</h5>
              <div className="add-car-data__column">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="additionally"
                    onChange={handleInputChange}
                    defaultChecked={
                      createData.additionally ===
                      "Дополнительный комплект колес"
                        ? true
                        : false
                    }
                    defaultValue={"Дополнительный комплект колес"}
                  />
                  <span></span>
                  Доп. комплект колёс
                </label>
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        {/* загрузить изображение */}
        <div className="add-car-data__item">
          {editId ? (
            <ImageUploaderEdit
              oldimage={image}
              market={market}
              setAttachmentOrder={setAttachmentOrder}
            />
          ) : (
            <ImageUploader
              setAttachmentOrder={setAttachmentOrder}
              keyTab={keyTab}
            />
          )}
        </div>
        {/*  */}

        {/* Видео ссылка на ютуб  */}
        <div className="add-car-data__item">
          <h5>Видео на YouTube</h5>
          <div className="add-car-data__flex add-car-data__search">
            <div className="input-wrap">
              <span>Обзорное видео</span>
              <input
                type="text"
                className="input-style "
                name="youtube_link"
                placeholder="Вставить ссылку"
                defaultValue={createData.youtube_link}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-wrap">
              <span>Видео диагностики</span>
              <input
                type="text"
                className="input-style "
                name="youtube_link_diagnostic"
                defaultValue={createData.youtube_link_diagnostic}
                placeholder="Вставить ссылку"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        {/*  */}

        {/* Видео ссылка на RUTUBE  */}
        <div className="add-car-data__item">
          <h5>Видео на Rutube</h5>
          <div className="add-car-data__flex add-car-data__search">
            <div className="input-wrap">
              <span>Обзорное видео</span>
              <input
                type="text"
                className="input-style "
                name="rutube_link"
                placeholder="Вставить ссылку"
                defaultValue={createData.rutube_link}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-wrap">
              <span>Видео диагностики</span>
              <input
                type="text"
                className="input-style"
                name="rutube__link_diagnostic"
                defaultValue={createData.rutube__link_diagnostic}
                placeholder="Вставить ссылку"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        {/*  */}

        <div className="add-car-data__item">
          {editId ? (
            <FileUploadFormEdit
              setCreateData={setCreateData}
              createData={createData}
              report={report}
              market={market}
            />
          ) : (
            <FileUploadForm
              setCreateData={setCreateData}
              createData={createData}
            />
          )}
        </div>

        <div className="add-car-data__item">
          <h4>Cсылка на бот</h4>
          <div className="add-car-data__flex input-tow-col">
            <div className="input-wrap w-100">
              <span>Ссылка</span>
              <input
                placeholder="Не указано"
                className="input-style "
                name="comment_link"
                defaultValue={createData.comment_link}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        {/* Описание автомобиля */}
        <div className="add-car-data__item">
          <h4>Описание автомобиля</h4>
          {/* <div className="add-car-data__flex input-tow-col">
            <div className="input-wrap w-50">
              <span>Шаблон описания</span>
              <div>
                <select
                  className="input-style custom-select"
                  data-placeholder="Не выбран"
                  name="states"
                  data-minimum-results-for-search="Infinity"
                >
                  <option value=""></option>
                  <option value="AL">Шаблон 1</option>
                  <option value="WY">Шаблон 2</option>
                </select>
              </div>
            </div>
          </div> */}
          <div className="add-car-data__flex input-tow-col">
            <div className="input-wrap w-100">
              <span>Описание</span>
              <textarea
                placeholder="Не указано"
                name="car_additional"
                defaultValue={createData.car_additional}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
        </div>
        {/*  */}

        {!market && (
          <div className="add-car-data__item">
            <h5>Готов к реализации?</h5>
            <div className="add-car-data__row">
              <div className="col-3">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="implementation"
                    defaultChecked={
                      createData.implementation === 1 ? true : false
                    }
                    onChange={handleInputChange}
                    defaultValue={1}
                  />
                  <span></span>
                  Да
                </label>
              </div>
              <div className="col-3">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="implementation"
                    defaultChecked={
                      createData.implementation === 0 ? true : false
                    }
                    onChange={handleInputChange}
                    defaultValue={0}
                  />
                  <span></span>
                  Нет
                </label>
              </div>
            </div>
          </div>
        )}

        {/* <button
          type="submit"
          className={`gradient-btn-blue ${
            isSubmitDisabled ? "" : "disablet-gradinet-btn"
          }`}
        >
          {result && result?.isLoading ? (
            <>
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Создается лот...</span>
            </>
          ) : (
            "Разместить лот"
          )}
        </button> */}
      </div>
    </>
  );
}

export default AutoInfo;
